export const handleDownload = () => {
  const fileName = "Tareez-Ghandour-CV.pdf";
  const pdfFilePath = "/assets/" + fileName;

  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + pdfFilePath;
  link.download = fileName || "downloaded-file.pdf";
  link.target = "_blank";

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const scrollToSectionById = (sectionId: string) => {
  const section = document.getElementById(sectionId);

  if (section) {
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    const offsetValue = section.getBoundingClientRect().top - 100;

    window.scrollBy({
      top: offsetValue,
      behavior: "smooth",
    });
  }
};

// Define language-specific data with explicit type annotations
const languageData: {
  en: {
    title: string;
    description: string;
  };
  ar: {
    title: string;
    description: string;
  };
  he: {
    title: string;
    description: string;
  };
} = {
  en: {
    title: "Tareez Ghandour - Web Developer Portfolio",
    description:
      "Explore Tareez Ghandour's portfolio showcasing web development projects, React expertise, and JavaScript skills.",
  },
  ar: {
    title: "تريز غندور - مطوره ويب",
    description:
      "استكشف موقع تريز غندور الذي يُظهر مشاريع الويب وخبرة React ومهارات JavaScript.",
  },
  he: {
    title: "תרז גנדור - פורטפוליו",
    description:
      "עיין בפורטפוליו של תרז גנדור המציג מגוון פרויקטי פיתוח, יכולת ב-React ומיומנויות ב-JavaScript.",
  },
};

// Function to update title and meta description based on language
export const updateTitleAndDescription = (language: string) => {
  // Validate the language parameter
  if (language !== "en" && language !== "ar" && language !== "he") {
    console.error(`Invalid language: ${language}`);
    return; // Exit the function if language is invalid
  }

  const { title, description } = languageData[language as "en" | "ar" | "he"];

  // Update title
  document.title = title;

  // Update meta description
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute("content", description);
  }
};

